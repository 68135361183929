<template>
  <div class="modal">
    <div class="modal-bg" @click="close(false, false)"></div>
    <div class="modal-block">
      <div class="modal-header d-block">
        <div class="d-flex justify-content-between align-items-center">
          <h3>View Documents</h3>
          <img src="@/assets/icons/archive.svg" class="icon" alt="Archive"
               @click="archiveDoc"
               content="Archive"
               v-tippy="{arrow: true, placement: 'left-center'}">
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="d-flex align-items-center">
            <div class="ms-2 me-3">
              <v-avatar :username="doc.borrowerName" rounded/>
            </div>
            <div>
              <div>{{ doc.borrowerName }}</div>
              <div class="text-muted">{{ doc.borrowerEmail }}</div>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Documents</div>
          <div v-for="(key, indexDate) in Object.keys(doc.docs)" :key="indexDate" class="mt-3">
            <div class="d-flex align-items-center justify-content-between" v-if="doc.docs[key].length">
              <div class="text-muted">{{ key }}</div>
            </div>
            <div class="doc-item mt-2" v-for="(file, index) in doc.docs[key]" :key="index">
              <div class="d-flex align-items-center justify-content-between">
                <img src="@/assets/icons/attach.svg" class="opacity-05 mx-2" alt="Edit">
                <span>{{ file.name }}</span>
              </div>
              <div>
                <img src="@/assets/icons/delete.svg" class="icon" alt="Edit" @click="deleteFile(file.letterDocId)"
                     content="Delete file"
                     v-tippy="{arrow: true, placement: 'left-center'}">
                <img src="@/assets/icons/download.svg" class="icon" alt="Edit" @click="downloadFile(file.id)"
                     content="Download"
                     v-tippy="{arrow: true, placement: 'left-center'}">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div class="d-flex">
          <base-button title="Download all" action="secondary-default" @click-btn="downloadAll()" />
          <base-button title="Request Documents" action="secondary" @click-btn="close(false, true)" class="ms-2" />
          <base-button title="Cancel" action="temp" @click-btn="close(false, false)" class="ms-2" />
        </div>
        <img src="@/assets/icons/delete.svg" class="icon me-2" alt="Edit"
             @click="deleteDoc"
             content="Delete"
             v-tippy="{arrow: true, placement: 'left-center'}">
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue'
export default {
  name: "ViewDocumentsModal",
  props: {
    doc: { type: Object, required: true },
  },
  components: {BaseButton},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    forwardToAgent() {},
    assignToLoan() {
      this.$http.post(`/api/v1/secure_inbox/assign/${1}`, {
        documentIds: this.getDocumentIds()
      })
        .then(() => this.close(true))
        .catch()
    },
    downloadFile(id) {
      this.goto(this.backendUrl('/mlo/secure_inbox/download_single_document/' + id))
    },
    deleteFile(id) {
      this.$http.delete(`/api/v1/pre-approved-letter/documents/delete/${id}`)
        .then(() => {
          Object.keys(this.doc.docs).forEach((key) => {
            this.doc.docs[key] = this.doc.docs[key].filter((item) => {
              return item.letterDocId !== id
            })
          })
        })
        .catch()
    },
    downloadAll() {
      let queryParams = new URLSearchParams({});
      queryParams.append('ids[]', this.doc.id)

      this.goto(this.backendUrl('/mlo/secure_inbox/download_archive?' + queryParams.toString()))
    },
    deleteDoc() {
      this.$http.delete(`/api/v1/pre-approved-letter/documents/delete/${this.doc.id}`)
        .then(() => this.close(true))
        .catch()
    },
    archiveDoc() {
      this.$http.get(`/api/v1/secure_inbox/archive/${this.doc.id}`)
        .then(() => this.close(true))
        .catch()
    },
    getDocumentIds() {
      let ids = [];

      for (const [, docs] of Object.entries(this.doc.docs)) {
        docs.forEach((doc) => {
          ids.push(doc.id)
        })
      }

      return ids
    },
    close(updateList = false, requestDocument = false) {
      this.$emit('close', updateList, requestDocument)
    }
  }
}
</script>
<style type="scss" scoped>
.modal .modal-body-container {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.action-btn {
  width: 100%;
  max-width: 140px;
  max-height: 44px;
  height: 44px;
}
</style>
