<template>
  <div class="page-filtration">
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex align-items-center">
          <div
            class="page-filtration__search"
            :class="{ 'page-filtration__search_active': ui.showSearchField }"
          >
            <button
              class="btn page-filtration__button"
              :class="{'page-filtration__button_disable': ui.showSearchField}"
              @click="toggleSearchField"
            >
              <img src="@/assets/icons/icon-search.svg" alt="" />
            </button>
            <div class="form-group">
              <label for="searchField">
                <input
                  type="text"
                  id="searchField"
                  class="form-control"
                  v-model="searchField"
                  @focusout="hideInput"
                  ref="searchField"
                  @input="search"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-center justify-content-end">
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          :auto-apply="true"
          :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
          :timePicker="false"
          class="daterange-dashboard"
          opens="left"
        >
          <template v-slot:input="picker">
            <img
              alt=""
              class="position-absolute"
              src="@/assets/icons/icon-calender.svg"
            />
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
        </date-range-picker>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "@/utils/debounce";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";

export default {
  name: "PreApprovalLettersFiltrations",
  components: { DateRangePicker },
  props: {
    startDate: String,
    endDate: String,
    allSources: Array,
    selectedSource: Object,
    query: String
  },
  data() {
    return {
      ui: {
        showSearchField: false
      },
      dateRange: {
        startDate: this.startDate,
        endDate: this.endDate
      },
      selectedSourceValue: this.selectedSource,
      searchField: this.query,
      search: debounce(() => {
        this.$emit("query-updated", this.searchField);
      }, 350)
    };
  },
  methods: {
    toggleSearchField() {
      this.ui.showSearchField = true;
      this.$refs.searchField.focus()
    },
    hideInput() {
      this.$refs.searchField.blur()
      this.ui.showSearchField = false
    }
  },
  watch: {
    dateRange: {
      handler(val) {
        this.$emit("daterange-update", val);
      },
      deep: true
    }
  },
  filters: {
    date(date) {
      return moment(String(date)).format("MM/DD/YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-filtration {
  padding: 18px 32px;
  min-width: fit-content;

  .daterange-dashboard {
    &::v-deep {
      min-width: 220px !important;
      .form-control {
        padding-left: 35px;
        border: 1px solid #d4d4d4;
        border-radius: 8px;
        background-color: #ffffff !important;
        line-height: 23px;
        img {
          left: 5px;
        }
      }
    }
  }

  &__button {
    position: relative;
    z-index: 2;
    top: -3px;

    &.page-filtration__button_disable {
      cursor: none;
      pointer-events: none;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__search {
    position: relative;
    min-width: 20px;
    margin-right: 10px;

    .form-group {
      position: absolute;
      opacity: 0;
      z-index: -1;
      top: 0;
      width: 0;
      transition: all 0.3s;

      input {
        height: 34px;
        border-radius: 16px;
        padding-left: 35px;
      }
    }

    &_active {
      min-width: 160px;
      .form-group {
        z-index: 0;
        min-width: 160px;
        opacity: 1;
      }
    }
  }
}
</style>
