<template>
  <div>
    <base-table :columns="columns" class="table">
      <template #table-body v-if="letters.length > 0">
        <tr v-for="(letter, index) in letters" :key="index">
          <td colspan="1" class="ps-3 mw-50  w-12">
            {{ letter.borrowerFirstName }} {{ letter.borrowerLastName }}
            <span v-if="letter.coBorrowerFirstName">/</span>
            {{ letter.coBorrowerFirstName }} {{ letter.coBorrowerLastName }}
          </td>
          <td colspan="1" class="ps-0 mw-50 w-12">
            {{ convertToCurrency(letter.loanAmount) }}
          </td>
          <td class="ps-0 mw-100">
            {{ letter.propertyAddress }}
          </td>
          <td colspan="1" class="ps-0 mw-50">
            <span v-if="letter.source === 'lendingpad'">LendingPad</span>
            <span v-else-if="letter.source === 'vision'">VisionCRM</span>
            <span v-else>New Prospect</span>
          </td>
          <td colspan="1" class="ps-0 mw-50">
            {{ formatDate(letter.createdAt, "MM/DD/YYYY") }}
          </td>
          <td colspan="1" class="ps-0">
          <span v-if="letter.docsReceived > 0">
            <span class="cursor-pointer" @click="$emit('openDocumentsModal', letter)">
              Docs Received
            </span>
          </span>
          </td>
          <td colspan="1" class="ps-0 w-12">
            <div class="d-flex justify-content-end align-items-center">
              <div class="d-flex">
                <div class="cursor-pointer" v-tippy="{arrow: true, placement: 'top-center'}" content="View">
                  <router-link :to="{ name: 'pre_approval_letter_single', params: { id: letter.id }}">
                    <img src="@/assets/icons/icon-preview.svg" alt="" />
                  </router-link>
                </div>
                <div class="cursor-pointer mx-3" @click="deleteLetter(letter.id)" v-tippy="{arrow: true, placement: 'top-center'}" content="Delete">
                  <img src="@/assets/icons/icon-delete.svg" alt="" />
                </div>
              </div>

              <base-button title="Request Docs" action="secondary" class="me-2" @click-btn="$emit('requestDocs', letter)" />

              <base-button-dropdown title="Download" @click-btn="downloadLetterRequest(letter.id)" :loading="letter.isDownloading"
                                    action="secondary" @click-sub-btn="showDownloadSettingsModal(letter.id)">
              </base-button-dropdown>
            </div>
          </td>
        </tr>
      </template>
      <template #table-body v-else>
        <tr>
          <td colspan="6" class="text-center">
            <div class="m-5">
              <strong class="no-result">No letters yet.</strong><br />
            </div>
          </td>
        </tr>
      </template>
    </base-table>
    <transition name="fade">
      <download-settings-modal v-if="ui.showDownloadSettingsModal" @close="closeSettings" @save="saveSettings" />
    </transition>
  </div>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import BaseButtonDropdown from '../../../components/BaseButtonDropdown.vue';
import BaseButton from '../../../components/BaseButton.vue';
import DownloadSettingsModal from "../modals/DownloadSettingsModal.vue";

export default {
  name: "PreApprovalLettersTable",
  components: {DownloadSettingsModal, BaseTable, BaseButtonDropdown, BaseButton },
  props: {
    letters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [
        "Prospect / Co",
        "Loan Amount",
        "Property Address",
        "Source",
        "Created on",
        "",
        ""
      ],
      ui: {
        showDownloadSettingsModal: false
      },
      downloadSettings: {
        letter: false,
        guide: false,
        handbook: false
      },
      letterId: null
    };
  },
  methods: {
    downloadLetter(id, withBuyer = false) {
      this.letter = this.letters.filter((el) => el.id === id)
      this.letter[0].isDownloading = true
      if (withBuyer) {
        this.$http
          .get(`/api/v1/pre-approved-letter/${id}/download?withBuyer=1`, {
            responseType: "arraybuffer"
          })
          .then(res => {
            let fileURL = window.URL.createObjectURL(
                new Blob([res.data], {
                  type: "application/zip"
                })
              ),
              fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "Pre-approval Letter + Home Buyer Guide.zip");

            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
          })
          .catch(() => this.pushAlert("error", "Failed Generate"))
          .finally(() => this.letter[0].isDownloading = false);
      } else {
        this.downloadLetterRequest(id)
      }
    },
    downloadLetterRequest(id) {
      const selectedLetter = this.letters.find(el => el.id === id);
      selectedLetter.isDownloading = true;
      this.$http
        .get(`/api/v1/pre-approved-letter/${id}/download-new?withBuyer=0`, {
          responseType: "arraybuffer",
          params: {
            letter: this.downloadSettings.letter,
            handbook: this.downloadSettings.handbook,
            guide: this.downloadSettings.guide,
            withBuyer: 0
          }
        })
        .then((res) => {
          // check if selected more than 1 document
          let downloadZip = Object.values(this.downloadSettings).filter(val => val === true).length > 1
          let newBlob = new Blob([res.data], {
            type: downloadZip ? "application/zip" : "application/pdf"
          });
          let fileURL = window.URL.createObjectURL(newBlob);
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let borrowerName = selectedLetter.borrowerFirstName + " " + selectedLetter.borrowerLastName;
          let fileName = "Pre-approval Letter.pdf"
          if (downloadZip) {
            fileName = "Pre-approval Letter.zip"
          } else {
            this.downloadSettings.letter ? fileName = `${borrowerName} - Pre-approval Letter.pdf` : ""
            this.downloadSettings.handbook ? fileName = `${borrowerName} - Home Loan Toolkit.pdf` : ""
            this.downloadSettings.guide ? fileName = `${borrowerName} - Home Buyer Guide.pdf` : ""
          }
          fileLink.setAttribute("download", fileName);

          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => {
          console.log(e)
          this.pushAlert("error", "Failed Generate")
        })
        .finally(() => {
          this.ui.downloading = false
          selectedLetter.isDownloading = false;
          this.downloadSettings = {
            letter: false,
            guide: false,
            handbook: false
          }
        });
    },
    downloadHomeLoanToolkit(id) {
      this.$http
        .get(`/api/v1/pre-approved-letter/${id}/download-toolkit`, {
          responseType: "arraybuffer"
        })
        .then(res => {
          let fileURL = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/pdf"
              })
            ),
            fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "home_loan_toolkit_.pdf");

          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        })
        .catch(() => this.pushAlert("error", "Failed Generate"))
        .finally(() => this.letter[0].isDownloading = false)
    },
    deleteLetter(id) {
      if (confirm("Are you sure?")) {
        this.$http
          .delete(`/api/v1/pre-approved-letter/${id}/delete`)
          .then(() => this.$emit("reload"))
          .catch(() => this.pushAlert("error", "Failed Generate"));
      }
    },
    showDownloadSettingsModal(id) {
      this.ui.showDownloadSettingsModal = true
      this.letterId = id

    },
    saveSettings(data) {
      this.downloadSettings = data
      this.downloadLetterRequest(this.letterId)
      this.ui.showDownloadSettingsModal = false
    },
    closeSettings() {
      this.ui.showDownloadSettingsModal = false
      this.downloadSettings = {
        letter: false,
        guide: false,
        handbook: false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  &::v-deep {
    thead {
      tr {
        th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
          opacity: 1;
          color: rgba($color: #000000, $alpha: 0.5);
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:first-child {
            width: 10%;
          }
        }
      }
    }
  }
  &.page-content__table_simple_border {
    thead {
      tr {
        th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
          opacity: 1;
          color: rgba($color: #000000, $alpha: 0.5);
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:first-child {
            width: 10%;
          }
        }
      }
    }
  }
}
.mw-100 {
  max-width: 100px!important;
}
.mw-50 {
  max-width: 50px!important;
}
.w-12 {
  width: 12% !important;
}
.custom-dropdown {
  width: fit-content;
  height: 32px;
  border: 1px solid #8a8a8a;
  border-radius: 8px 8px;

  &__btn-action {
    height: 100%;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 11px;
    background: none;
    padding: 10px 19px;
    border-width: 0 1px 0 0;
    border-right: 1px solid #8a8a8a;
  }

  &__btn-trigger {
    height: 100%;
    background: none;
    border: none;
    padding: 0 7px;
  }

  &__triangle {
    width: 0;
    height: 0;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    border-top: 4.5px solid #000000;
  }

  &__body {
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 13px;
    }
  }
}
</style>
