<template>
  <div>
    <base-page-header title="Pre-Approval Management" backLink="resource_center">
      <template #buttons>
        <div class="d-flex justify-content-end">
          <router-link :to="{ name: 'pre_approval_letter_create' }" class="text-decoration-none">
            <base-button title="Create" action="primary" />
          </router-link>
        </div>
      </template>
    </base-page-header>

    <!-- Page Filtration -->
    <pre-approval-letters-filtrations
      :allSources="allSources"
      :selectedSource="source"
      :query="query"
      :startDate="startDate"
      :endDate="endDate"
      @paging-updated="updatePagging"
      @source-updated="updateSource"
      @query-updated="updateQuery"
      @daterange-update="updateDateRange"
    />

    <!-- Page Content -->
    <base-page-content>
      <template #content>
        <page-spinner v-if="ui.preLoading" />
        <pre-approval-letters-table
          v-else
          :letters="letters"
          @reload="getLetters"
          @requestDocs="openModal"
          @openDocumentsModal="openDocumentsModal"
        />
        <Paging
          :page="currentPage"
          :paging="paging"
          @limitChanged="updatePagging"
          @pageChanged="updatePagging"
        />
        <request-documents-modal v-if="isOpenRequestDocumentsModal"
                                 :receiver="receiver"
                                 @close="isOpenRequestDocumentsModal = false" />
        <documents-modal v-if="isOpenDocumentsModal"
                         :letter-id="receiver.id"
                         :doc="letter"
                         @close="isOpenDocumentsModal = false" />
      </template>
    </base-page-content>
  </div>
</template>

<script>
import PageSpinner from "../../components/pageSpinner";
import PreApprovalLettersFiltrations from "./includes/PreApprovalLettersFiltrations.vue";
import PreApprovalLettersTable from "./includes/PreApprovalLettersTable.vue";
import BasePageHeader from "../../components/BasePageHeader";
import Paging from "../../components/Paging.vue";
import moment from "moment";
import BasePageContent from "../../components/BasePageContent";
import BaseButton from '../../components/BaseButton.vue';
import RequestDocumentsModal from "./modals/RequestDocumentsModal";
import DocumentsModal from "./modals/ViewDocumentsModal"

export default {
  name: "PreApprovalLetter",
  components: {
    BasePageContent,
    BasePageHeader,
    PreApprovalLettersTable,
    PageSpinner,
    PreApprovalLettersFiltrations,
    Paging,
    BaseButton,
    RequestDocumentsModal,
    DocumentsModal
  },
  data() {
    return {
      letters: [],
      currentPage: 1,
      paging: {
        offset: 0,
        limit: 10,
        total: 0
      },
      allSources: [
        {
          label: "All",
          value: "all"
        },
        {
          label: "VisionCRM",
          value: "vision"
        },
        {
          label: "LendingPad",
          value: "lendingpad"
        },
        {
          label: "New Prospect",
          value: "custom"
        }
      ],
      source: {
        label: "All",
        value: "all"
      },
      query: "",
      startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      ui: {
        createModal: false,
        preLoading: false
      },
      isOpenRequestDocumentsModal: false,
      isOpenDocumentsModal: false,
      receiver: {},
      letter: {}
    };
  },
  methods: {
    showCreateModal() {
      this.ui.createModal = true;
    },
    openModal(receiver) {
      this.receiver = receiver
      this.isOpenRequestDocumentsModal = true
    },
    closeModal() {},
    openDocumentsModal(letter) {
      this.letter = letter
      this.isOpenDocumentsModal = true
    },
    getLetters() {
      this.ui.preLoading = true;

      this.$http
        .get("/api/v1/pre-approved-letters", {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
            query: this.query.trim(),
            source: this.source.value,
            offset: this.paging.offset,
            limit: this.paging.limit
          }
        })
        .then(res => {
          this.letters = res.data.letters.map((el) => {
            el.isDownloading = false
            return el;
          });
          this.paging = res.data.paging;
        })
        .catch(err => this.alertError(err.response.statusText))
        .finally(() => (this.ui.preLoading = false));
    },
    updatePagging(paging) {
      this.paging = paging;
      this.getLetters();
    },
    updateSource(source) {
      this.source = source;
      this.getLetters();
    },
    updateQuery(query) {
      this.query = query;
      this.paging.offset = 0;
      this.getLetters();
    },
    updateDateRange(dateRange) {
      this.startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
      this.endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
      this.getLetters();
    },
    addNewLetter() {
      this.$router.push("/resource-center/pre-approval-letters/create");
    },
    pagingUpdated(paging) {
      this.currentPage = paging.currentPage;
    },
    changeSource(source) {
      this.selectedSourceValue = source;
    }
  },
  beforeMount() {
    this.getLetters();
  }
};
</script>

<style>
.page-header {
  padding-top: 12px;
}
</style>
